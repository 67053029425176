import './Security.scss'
import { useState } from 'react'
import axios from 'axios'
import { API_ROUTES } from '../../utils/constants'

function Security(){
  
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')

    const [message, setMessage] = useState('')

    const token = sessionStorage.getItem('token')

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };const handleNewPassword2Change = (event) => {
        setNewPassword2(event.target.value);
    };

    const clearFields = () => {
        setPassword('')
        setNewPassword('')
        setNewPassword2('')
    }

    async function editPassword(password, newPassword){
        try{
            await axios({
                method: 'put',
                url: `${API_ROUTES.PROFILE}`,
                data: {password, newPassword},
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setMessage('Password has been updated!');
            clearFields();
        }
        catch(error){
            console.error('Error updating password', error);
        }
    }

    const handleApplyClick = () => {
        if(password === '' || newPassword === '' || newPassword2 === ''){
            setMessage('Fill every field')
        }
        if(newPassword !== newPassword2){
            setMessage('The new password fields must be identical')
        }
        editPassword(password, newPassword);
    }

    return (
        <div className="security-details">
            <form>
                <div className="message">{message}</div>
                <label>Current password</label>
                <input type="password" name="password" value={password} onChange={handlePasswordChange}/>
                <label>New password</label>
                <input type="password" name="newPassword" value={newPassword} onChange={handleNewPasswordChange}/>
                <label>Repeat password</label>
                <input type="password" name="newPassword2" value={newPassword2} onChange={handleNewPassword2Change}/>
                <input className='button' value={'Apply'} onClick={handleApplyClick}/>
            </form>
        </div>
    )
}

export default Security