import Step from "../step/Step"
import './TaskInfo.scss'
import { IoAdd } from "react-icons/io5";
import axios from "axios";
import { API_ROUTES } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';

import { useEffect, useState } from "react"

function TaskInfo({taskInfoOpen, tasks, setTasks, editTask, taskId, getTasks, token, admin, userId}) {
      // eslint-disable-next-line
  const [stepName, setStepName] = useState('');

      // eslint-disable-next-line
  const [stepDone, setStepDone] = useState(false)

  const [barTypeValue, setBarTypeValue] = useState(false);
  const [barDisplay, setBarDisplay] = useState(false);
  const [barColor, setBarColor] = useState(false);

  const currentSteps = tasks.find(task => task._id === taskInfoOpen)?.steps

  async function deleteStep(stepId){
    try{
      await axios({
          method: 'delete',
          url: `${API_ROUTES.TASKS}`,
          data: {stepId},
          headers: {
              'Authorization': `Bearer ${token}`,
          },
      });
      currentSteps.filter(step => step.stepId !== stepId); // Mettre à jour l'état tasks avec les données récupérées
      getTasks();
    }
    catch(error){
        console.error('Error fetching tasks:', error);
    }
  }

  const addStep = () => {
    const newStep = {
      stepId: uuidv4(), // Générez un nouvel ID pour le nouveau step
      stepName: "",
      done: false
    };
    addStepToTask(newStep, taskId); // Ajoutez un nouveau step
    getTasks()
};

  // Fonction pour ajouter les étapes à la tâche
  async function addStepToTask(newStep, taskId) {
    try {
        const updatedTask = tasks.find(task => task._id === taskId); // Trouver la tâche correspondant à l'ID
        if (!updatedTask) {
            console.error('Task not found');
            return;
        }

        // Checking if the step already exists
        const existingStepIndex = updatedTask.steps.findIndex(step => step.stepId === newStep.stepId);
        // If index is different than -1 ( -1 cannot exists in an array so if we get -1 it means that step hasn't been found.)
        if (existingStepIndex !== -1) {
          // Update the task with the edited step.
          const updatedSteps = [...updatedTask.steps];
          updatedSteps[existingStepIndex] = newStep;
          const newData = {
              ...updatedTask,
              steps: updatedSteps
          };
          setTasks(tasks.map(task => (task._id === taskId ? newData : task))); // Mettre à jour l'état des tâches avec la tâche modifiée
          editTask(taskId, newData); // Appeler la fonction editTask pour enregistrer les modifications sur le serveur
        } else{
          const updatedSteps = [...updatedTask.steps, newStep]; // Ajouter la nouvelle étape à la liste des étapes de la tâche
          const newData = {
              ...updatedTask,
              steps: updatedSteps
          };
          setTasks(tasks.map(task => (task._id === taskInfoOpen ? newData : task))); // Mettre à jour l'état des tâches avec la tâche modifiée
          editTask(taskId, newData); // Appeler la fonction editTask pour enregistrer les modifications sur le serveur
        }
        
      } 
      catch (error) {
        console.error('Error adding steps to task:', error);
    }
  }

  // Calculates the percentage of done steps
  const completedSteps = currentSteps.filter(step => step.done).length;
  const progressPercentage = Math.round((completedSteps / currentSteps.length) * 100);

  // Display progress bar only if there are steps in the task
  const displayProgress = () => {
    if(currentSteps.length > 0){
      setBarDisplay(true)
    } else{
      setBarDisplay(false)
    }
  };
  useEffect(() => {
    displayProgress();
    // eslint-disable-next-line
  },[currentSteps]);

  // Change bar's value on click ( percentage / fraction ) 
  const progressValue = () => {
    setBarTypeValue(!barTypeValue)
  };

  useEffect(() => {
    // Checking if all steps are completed
    if (completedSteps === currentSteps.length) {
        // if so, change bar color
        setBarColor(true);
    } else {
        // if not / if unchecking a step, change the color back to default
        setBarColor(false);
    }
}, [completedSteps, currentSteps]); // Continiously tracks changes in the steps

  return (
    <div>
      {
        barDisplay && 
          <div className="bar-container">
            <div className="progress-bar">
              <div className={`progress-bar-fill`} onClick={progressValue} 
                style={{ width: `${progressPercentage}%`, backgroundColor: `${barColor ? 'white' : 'black'}`, color: `${barColor ? 'black' : 'white'}` }}>
                {barTypeValue ? `${completedSteps}/${currentSteps.length}` : `${progressPercentage}%`}
              </div>
            </div>
          </div>
      }
        {currentSteps.map((step, stepId) => (
          <Step key={step.stepId} addStepToTask={addStepToTask}
          getTasks={getTasks}
          deleteStep={() => deleteStep(step.stepId, taskId)}
          step={step}
          taskId={taskId}
          stepId={stepId}
          stepName={step.stepName}
          setName={setStepName}
          stepDone={step.done}
          setStepDone={setStepDone}
          admin={admin}
          userId={userId}/>
      ))}
      <IoAdd className='icon add-step' onClick={addStep} />
    </div>
  )
}

export default TaskInfo