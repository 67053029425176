import '../pages-styles/PreHome.scss'
import {Link} from 'react-router-dom'
import {React, useState} from 'react'
import Header from '../components/header/Header'

function PreHome({setTaskInfoOpen}){

    const token = sessionStorage.getItem('token')
    // eslint-disable-next-line
    const [isLoggedIn, setIsLoggedIn] = useState(token);
    

    return (
        <div className="welcome-container">
            <Header setTaskInfoOpen={setTaskInfoOpen}/>
            <div className="intro-container">
                <div className="intro-box">
                    <div className="title">
                        <h1>Todo</h1>
                    </div>
                    <div className="intro-text">
                        <h3>Plan everything,</h3>
                        <h3>Don't forget a thing.</h3>
                    </div>
                    <div className="button">
                        {isLoggedIn ? (
                        <Link to='/Tasks' className='start-button'>Start</Link>
                        ) : (
                        // Sinon, afficher le lien "Connexion / Inscription"
                        <Link to='/sign' className='start-button'>Start</Link>
                        )}
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default PreHome