import './Header.scss';
import { Link } from 'react-router-dom'
import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { API_ROUTES } from '../../utils/constants';

import { IoHome } from "react-icons/io5";
import { GrTask } from "react-icons/gr";
import { FaUser } from "react-icons/fa";
import { IoIosLogIn, IoIosLogOut } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi";


function Header({setTaskInfoOpen}){
    const token = sessionStorage.getItem('token')
    // eslint-disable-next-line
    const [isLoggedIn, setIsLoggedIn] = useState(token);

    const [userName, setUserName] = useState('')

    const [iconHeader, setIconHeader] = useState(false)

    const checkWindowWidth = (event) => {
        if(window.innerWidth < 600){
            setIconHeader(true)
        }
    }

    useEffect(() => {
        checkWindowWidth();
    }, [])

    const handleLogout = () => {
        sessionStorage.clear();
        window.location = '/';
    };

    async function getUserInfo() {
        if(token){
            const response = await axios ({
                method: 'get',
                url: `${API_ROUTES.PROFILE}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const userInfo = response.data.user
            setUserName(userInfo.username)
        }  
    }

    useEffect(() => {
        getUserInfo()
        // eslint-disable-next-line
    }, []);

    return(
        <div className="header-container">
                <div className="nav-links">
                    <Link to='/'><span>{iconHeader ? <IoHome/> : 'Home'}</span></Link>
                    {isLoggedIn ? ( // If logged, display logged only links
                        <div className='logged-links'>
                            <Link to="/Tasks"><span onClick={() => setTaskInfoOpen(null)}>{iconHeader ? <GrTask/> : 'My tasks'}</span></Link>
                             <Link to="/OpenSpace"><span onClick={() => setTaskInfoOpen(null)}>{iconHeader ? <HiUserGroup /> : 'Open Space'}</span></Link>
                            <Link to="/Profile"><span>{iconHeader ? <FaUser/> : userName ? userName : 'Profile'}</span></Link>
                            <Link to='/'><span className='logout' onClick={handleLogout}>{iconHeader ? <IoIosLogOut/> : 'Sign out'}</span></Link>
                        </div>
                    ) : (
                        // Else, display Sign in / up link
                        <Link to='/sign'><span>{iconHeader ? <IoIosLogIn/> : userName ? userName : 'Sign in / Sign up'}</span></Link>
                        )}
                </div>
            </div>
    )
}

export default Header