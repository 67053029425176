import './News.scss'
import { CgClose } from "react-icons/cg";

function News({closeModal}){
    return(
        <div className="news-background">
            <div className="news-modal">
            <CgClose  className='close-button' onClick={closeModal}/>
                <h1>What's new ?</h1>
                <div className="news-text">
                    <h2>Update : Open Space</h2>
                    <p>The open space section is now available. <br/>
                        . Create private spaces to work with your team, manage members and their permissions.<br/>
                        . Members can see the tasks and the included steps, and mark them as done.<br/>
                        . Admins can promote members to admin and manage the content ( edit / delete the tasks and steps ).<br/>
                        . The space creator ( manager ) has every admin permission and is the only one able to demote members.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default News