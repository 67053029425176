import './App.css';
import './fonts/Poppins-Black.ttf'
import PreHome from './pages/PreHome';
import Tasks from './pages/Tasks';
import Profile from './pages/Profile';
import Sign from './pages/Sign';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OpenSpace from './pages/OpenSpace';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_ROUTES } from './utils/constants';
//eslint-disable-next-line
import { HiQuestionMarkCircle } from "react-icons/hi2";
//eslint-disable-next-line
import News from './components/news/News';

function App() {

  const [openModal, setOpenModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [spaceEditModal, setSpaceEditModal] = useState(false)
  const [spaceModal, setSpaceModal] = useState(false)
  const [memberModal, setMemberModal] = useState(false)
  const [newsModal, setNewsModal] = useState(false)

  const [admin, setAdmin] = useState([]);
  const [members, setMembers] = useState([]);

  const [tasks, setTasks] = useState([]);
  const [steps, setSteps] = useState([]);

  const [taskInfoOpen, setTaskInfoOpen] = useState(null);

  const [taskIdToUpdate, setTaskIdToUpdate] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);

  const token = sessionStorage.getItem('token');
  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    console.log('click')
  }, [newsModal])

  const handleTaskClick = (taskId) => {
    setTaskInfoOpen(taskId === taskInfoOpen ? null : taskId);
  };

  const handleSpaceClick = (spaceId) => {
    setSelectedSpace(spaceId === selectedSpace ? null : spaceId)
  };

  // Create new task
  async function addTask(taskData) {
    const { name, description, newStep, selectedSpace } = taskData; // taskData contient toutes les propriétés
    
    const task = {
        userId,
        name,
        description,
        newStep,
        selectedSpace
    };
    
    const bodyFormData = new FormData();
    bodyFormData.append('task', JSON.stringify(task));
    
    try {
        const response = await axios({
            method: 'post',
            url: `${API_ROUTES.TASKS}`,
            data: bodyFormData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        setTasks([...tasks, task])
        getTasks()

        return response;
    } catch (err) {
        console.error(err);
        return { error: true, message: err.message };
    }
  }   
    async function getTasks() {
        try {
            const response = await axios({
                method: 'get',
                url: `${API_ROUTES.TASKS}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const fetchedTasks = response.data.tasks;
            setTasks(fetchedTasks); // Mettre à jour l'état local tasks avec les données récupérées

            const steps = fetchedTasks.map(task => task.steps).flat()
            setSteps(steps);
        }
        catch(error){
            console.error('Error fetching tasks:', error);
        }
    };
    // Get task on first render
    useEffect(() => {
        getTasks();
        // eslint-disable-next-line
    },[]);

    async function deleteTask(taskId){
        try{
            await axios({
                method: 'delete',
                url: `${API_ROUTES.TASKS}`,
                data: {taskId},
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setTasks(tasks.filter(task => task._id !== taskId)); // Mettre à jour l'état tasks avec les données récupérées
        }
        catch(error){
            console.error('Error fetching tasks:', error);
        }
    }

    async function editTask(taskId, newData){
/*         const taskToUpdate = tasks.filter((task) => task._id === taskId);
 */
        try{
            await axios({
                method: 'put',
                url: `${API_ROUTES.TASKS}`,
                data: {taskId, newData},
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setTasks(tasks.map(task =>
                task._id === taskId ? { ...task, ...newData } : task
            ));
        }
        catch(error){
            console.error('Error fetching tasks:', error);
        }
    };  

  return (
      <Router>
        <Routes>
          <Route path="/" element={<PreHome 
          setTaskInfoOpen={setTaskInfoOpen}
          />}
          />  
          <Route path="/Tasks" element={<Tasks 
          userId={userId}
          token={token}
          openModal={openModal} 
          setOpenModal={setOpenModal} 
          editModal={editModal} 
          setEditModal={setEditModal} 
          addTask={addTask} 
          getTasks={getTasks} 
          editTask={editTask} 
          deleteTask={deleteTask} 
          tasks={tasks} 
          setTasks={setTasks} 
          steps={steps} 
          setSteps={setSteps}
          taskIdToUpdate={taskIdToUpdate}
          setTaskIdToUpdate={setTaskIdToUpdate}
          taskInfoOpen={taskInfoOpen}
          setTaskInfoOpen={setTaskInfoOpen}
          handleTaskClick={handleTaskClick}
          admin={admin}
          setNewsModal={setNewsModal}
          newsModal={newsModal}
          members={members}
          />} 
          />  

          <Route path="/OpenSpace" element={<OpenSpace 
          userId={userId}
          openModal={openModal} 
          setOpenModal={setOpenModal} 
          spaceModal={spaceModal} 
          setSpaceModal={setSpaceModal} 
          addTask={addTask} 
          getTasks={getTasks} 
          editTask={editTask} 
          editModal={editModal}
          setEditModal={setEditModal}
          deleteTask={deleteTask} 
          tasks={tasks} 
          setTasks={setTasks} 
          steps={steps} 
          setSteps={setSteps}
          taskIdToUpdate={taskIdToUpdate}
          setTaskIdToUpdate={setTaskIdToUpdate}
          taskInfoOpen={taskInfoOpen}
          setTaskInfoOpen={setTaskInfoOpen}
          handleTaskClick={handleTaskClick}
          selectedSpace={selectedSpace}
          setSelectedSpace={setSelectedSpace}
          memberModal={memberModal}
          setMemberModal={setMemberModal}
          spaceEditModal={spaceEditModal}
          setSpaceEditModal={setSpaceEditModal}
          admin={admin}
          setAdmin={setAdmin}
          handleSpaceClick={handleSpaceClick}
          setNewsModal={setNewsModal}
          newsModal={newsModal}
          members={members}
          setMembers={setMembers}
          />} 
          />  
          <Route path="/Profile" element={<Profile
          userId={userId}
          tasks={tasks}
          steps={steps}
          token={token}
          setTasks={setTasks}
          setSteps={setSteps}
          setTaskInfoOpen={setTaskInfoOpen}
          />} 
          />  
          <Route path="/Sign"  element={<Sign />} />
        </Routes>
      </Router>
  );
}

export default App;
