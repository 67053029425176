import './Modal.scss';
import { useState } from 'react';

import { CgClose } from "react-icons/cg";
import { MdAdminPanelSettings } from "react-icons/md";
import { FaCrown } from "react-icons/fa6";
import { IoIosRemoveCircle } from "react-icons/io";

function Modal({ addTask, editTask, addSpace, closeModal, openModal, editModal, spaceModal, taskIdToUpdate, taskName: initialTaskName, 
    taskDescription: initialTaskDescription, selectedSpace, memberModal, editSpace, admin, creator, members, userId, spaceEditModal}) {
    // Change inputs default value depending on there is already one or not
    const [name, setName] = useState(editModal ? initialTaskName : '');
    const [description, setDescription] = useState(editModal ? initialTaskDescription : '');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const spaceMembers = members[selectedSpace] || [];

    const handleAddTask = async () => {
        try {
            if (!name.trim()) {
                setErrorMessage('Task name is required');
                return;
            }
            await addTask({ name, description, selectedSpace });
            closeModal();
        } catch (error) {
            setErrorMessage(error.response.data.message);
        }
    };

    const handleEditTask = async () => {
        try {
            const newData = {
                name: name,
                description: description
            };
            
            await editTask(taskIdToUpdate, newData);
            closeModal();
        } catch (error) {
            setErrorMessage(error.message);
        }
    };
    const handleEditSpace = async () => {
        try {
            const newData = {
                name: name,
                description: description
            };
            
            await editSpace(selectedSpace, newData);
            closeModal();
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    //Sending new openspace data to post request
    const handleAddSpace = async () => {
        try {
            if (!name.trim()) {
                setErrorMessage('Space name is required');
                return;
            }
            await addSpace(name);
            closeModal();
        } catch (error) {
            setErrorMessage(error.response.data.message);
        }
    }
    const handleAddMember = async () => {
        try {
            if (!email.trim()) {
                setErrorMessage('Email field is required');
                return;
            }
            const newData = {email}
            await editSpace(selectedSpace, newData);
        } catch (error) {
            setErrorMessage(error.response.data.message);
        }
    }

    const promoteAdmin = (memberId) => {
        if(admin.includes(userId)){
            const newData = {memberId}
            editSpace(selectedSpace, newData)
        }
    }
    const removeAdmin = (deleteAdmin) => {
        if(creator.includes(userId)){
            const newData = {deleteAdmin}
            editSpace(selectedSpace, newData)
        }
    }
    const removeMember = (deleteMember) => {
        if(creator.includes(userId)){
            const newData = {deleteMember}
            editSpace(selectedSpace, newData)
        }
    }
    
    return (
        <div className="modal-wrapper">
            <div className="modal">
                <CgClose  className='close-button' onClick={closeModal}/>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {
                    editModal && 
                    <> 
                    <h1>Edit task</h1>
                        <input type="text" name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter name'/>
                        <textarea name="description" value={description} onChange={(e) => setDescription(e.target.value)} cols="30" rows="10" placeholder='Enter description'></textarea>
                        <button onClick={handleEditTask}>Edit my task</button>
                    </>
                }
                {
                    openModal &&
                    <>
                    <h1>New task</h1>
                        <input type="text" name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter name' required/>
                        <textarea name="description" value={description} onChange={(e) => setDescription(e.target.value)} cols="30" rows="10" placeholder='Enter description'></textarea>
                        <button onClick={handleAddTask}>Add my task</button>
                    </>
                }
                {
                    spaceModal && 
                    <>
                    <h1>New space</h1>
                        <input type="text" name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter name' required/>
                        <button onClick={handleAddSpace}>Create a shared space</button>
                    </>
                }
                {
                    spaceEditModal &&
                    <>
                    <h1>Edit space</h1>
                        <input type="text" name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter new name' required/>
                        <button onClick={handleEditSpace}>Edit this space</button>
                    </>
                }
                {
                    memberModal &&
                    <>
                    <h1>Members</h1>
                    <div className="members">
                    { 
                        spaceMembers.map(member => (
                            <div className="member">
                                <div key={member._id}>{member.username}</div>
                                {/* Admin members ( black icon ) */}
                                {admin.includes(member._id) ?
                                <MdAdminPanelSettings 
                                    // Only the creator can see 'unpromote', because they're the only one who can unpromote someone
                                    title={creator.includes(userId) && member._id !== userId ? 'Unpromote' : 'Admin'} 
                                    // Only the creator can see the pointer, because they're the only one who can unpromote someone
                                    className={(creator.includes(userId) && member._id !== userId) ? 'admin pointer' : 'admin'} 
                                    // Only the creator can unpromote someone (other than themselves)
                                    onClick={(creator.includes(userId) && member._id !== userId) ? 
                                        () => removeAdmin(member._id) : null }
                                /> 
                                :
                                /* Non-admin members ( grey icons ) */
                                <MdAdminPanelSettings 
                                    // Only admins can see 'promote to admin', because they're the only ones who can promote a member
                                    title={admin.includes(userId) ? 'Promote to admin' : 'Member'} 
                                    // Only admins can see the pointer, because they're the only ones who can promote a member
                                    className={admin.includes(userId) ? 'not-admin pointer' : 'not-admin'} 
                                    // Only admins can promote a member
                                    onClick={admin.includes(userId) ? () => promoteAdmin(member._id) : null }
                                />
                                }
                                {creator.includes(member._id) &&
                                    <FaCrown title='Space manager'/>
                                }
                                {(
                                    (creator.includes(userId) && !creator.includes(member._id)) || (admin.includes(userId) && !admin.includes(member._id))
                                ) &&
                                    <IoIosRemoveCircle title='Remove member' className='pointer' onClick={() => removeMember(member._id)}/>
                                }
                            </div>
                        ))
                    }
                    </div>
                    {admin.includes(userId) &&
                        <div className="admin-buttons">
                            <h1>Add a member</h1>
                            <input type="text" name='email' onChange={(e) => setEmail(e.target.value)} placeholder="Member's Email" required/>
                            <button onClick={handleAddMember}>Add</button>
                        </div>
                    }
                    </>
                }
            </div>
        </div>
    );
}

export default Modal;
