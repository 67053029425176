import './Step.scss';
import { useState, useRef, useEffect } from 'react';

import { FaRegTrashAlt } from "react-icons/fa";

function Step({ stepName, addStepToTask, deleteStep, step, taskId, stepDone, setStepDone, stepId, admin, userId }) {
    const [name, setName] = useState(stepName); // Utilisez un nom différent pour l'état local du nom de l'étape pour éviter les conflits
    const inputRef = useRef(null); // Référence à l'input
    
    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);

        setTimeout(() => {
            if (step.stepId) {
                const editedStep = {
                    ...step,
                    stepName: newName,
                };
                addStepToTask(editedStep, taskId); // Mettez à jour le step existant
            }
        }, 1000)
        
    };
    
    const markAsDone = () => {
        if(step.stepId){
            const updatedStep = {
                ...step,
                done: !stepDone // Utilisez la valeur actuelle de stepDone ici
            };
            setStepDone(!stepDone); // Mettez à jour l'état local
            addStepToTask(updatedStep, taskId)
        }
    }

    // Fonction pour mettre à jour la largeur de l'input
    const updateInputWidth = () => {
        if (inputRef.current) {
            inputRef.current.style.width = 'auto';
            const inputWidth = inputRef.current.scrollWidth;
            inputRef.current.style.width = inputWidth + 'px';
        }
    };

    // Appel de la fonction lors du chargement initial et à chaque fois que le texte change
    useEffect(() => {
        updateInputWidth(); // Mettre à jour la largeur lors du chargement initial
        // Mettre à jour la largeur lors du redimensionnement de la fenêtre (si nécessaire)
        window.addEventListener('resize', updateInputWidth);
        return () => {
            window.removeEventListener('resize', updateInputWidth);
        };
    }, [name]);

    return (
        <div className='step'>
            <div type="checkbox" className={`checkbox ${stepDone && 'checked'}`} onClick={markAsDone}></div>
                <input
                type="text"
                className='step-name'
                name='step-name'
                placeholder="Enter step name"
                value={name} // Assurez-vous que la valeur du champ de saisie est contrôlée par l'état local
                onChange={handleNameChange} // Mettez à jour l'état local du champ de saisie
                ref={inputRef}
            />
                <FaRegTrashAlt className='step-icon' onClick={() => deleteStep(stepId)} />
        </div>
    );
}

export default Step;