import { API_ROUTES } from '../../utils/constants';
import axios from 'axios';
import './GeneralInfo.scss';
/* import { AiOutlinePicture } from "react-icons/ai";
 */import { useEffect } from 'react';

function GeneralInfo({tasks, setTasks, setSteps, steps, userId, token, userName, setUserName, imageUrl, handleImageChange }){

    const filteredTasks = tasks.filter(task => task.spaceId !== null && task.userId === userId);


    async function getTasks() {
        try {
            const response = await axios({
                method: 'get',
                url: `${API_ROUTES.TASKS}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const fetchedTasks = response.data.tasks;
            setTasks(fetchedTasks)
            
            const steps = fetchedTasks.map(task => task.steps).flat()
            setSteps(steps);
        }
        catch(error){
            console.error('Error fetching tasks:', error);
        }
    };

    useEffect(() =>{
        getTasks()
            // eslint-disable-next-line
    }, [])

    /* const loadFile = (event) => {
        const file = event.target.files[0];
        handleImageChange(URL.createObjectURL(file));
        console.log(file)
        // Création de l'objet FormData
        const formData = new FormData();
        formData.append('userId', userId);
        if (file) {
            formData.append('image', file); // Ajouter le fichier à FormData
        }
    
        // Envoi de la requête au backend
        updateProfile(null, formData); // Passer l'objet FormData ici
    }; */
    

    const handleUserNameChange = (e) => {
        const newName = e.target.value;
        setUserName(newName);

        setTimeout(() => {
            updateProfile(newName)
        }, 1000)  
    };

    async function updateProfile(newName, file) {
        try {
            const formData = new FormData();
        formData.append('userId', userId);
        
        if (newName) {
            formData.append('newName', newName);
        }
        if (userName) {
            formData.append('userName', userName);
        }
        if (file) {
            formData.append('image', file); // Ajouter le fichier à FormData
        }
            console.log('Contenu du formdata: ',formData)
    
            const response = await axios({
                method: 'put',
                url: `${API_ROUTES.PROFILE}`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('username depuis GeneralInfo : ', userName)
            console.log('Picture added:', response.data); // Afficher la réponse de l'API
        } catch (error) {
            console.error('Error updating profile:', error); // Gérer les erreurs
            // Afficher un message d'erreur à l'utilisateur, par exemple
        }
    }

    /* function handleClicRedirection(){
        document.getElementById('add-photo').click()
    } */
    const tasksLenght = filteredTasks.length;
    // Looks for every task that has all of its steps marked as done
    const completedTasks = filteredTasks.filter(task => task.steps.every(step => step.done)).length;
    const totalProgress = Math.round((completedTasks / tasksLenght)*100);
    

    return (
        <div className="details">
            <div className="details-head">
                {/* <div className="image" onClick={handleClicRedirection}>
                    <input type="file" name='image' accept="image/*" id='add-photo' onChange={(event) => {loadFile(event)}} hidden/> */}
                    {/* eslint-disable-next-line */}
                    {/* <img id="output" src={imageUrl}/>
                    <AiOutlinePicture className='image-icon'/> */}
{/*                 </div>
 */}                <input className='username' placeholder='Enter username' value={userName} onChange={handleUserNameChange}/>
            </div>
            <div className="details-body">
                <div className="stats">
                    <div className="active-tasks-container">
                        <h3 className='active-tasks'>Active tasks</h3>
                        <span className='active-tasks-number'>{tasksLenght}</span>
                    </div>
                    <div className="completed-tasks-container">
                        <h3 className='completed-tasks'>Completed tasks</h3>
                        <span className='completed-tasks-number'>{completedTasks}</span>
                    </div>
                </div>
                <div className="progress">
                    <h3>Total progress</h3>
                    <div className="bar-container">
                        <div className="progress-bar">
                            <div className="total-progress" style={{ width: `${totalProgress}%`}}>{totalProgress}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralInfo