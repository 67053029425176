import '../pages-styles/OpenSpace.scss';
import Header from '../components/header/Header';
import Modal from '../components/modal/Modal';
import TaskInfo from '../components/taskinfo/TaskInfo';
import News from '../components/news/News';

import { IoAdd } from "react-icons/io5";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_ROUTES } from '../utils/constants';
import { FaEdit, FaRegTrashAlt, FaUser } from 'react-icons/fa';
//eslint-disable-next-line
import { HiQuestionMarkCircle } from "react-icons/hi2";

function OpenSpace({tasks, editModal, setEditModal, openModal, setOpenModal, setSpaceModal, spaceModal, addTask, getTasks, editTask, deleteTask, taskIdToUpdate, 
    setTaskIdToUpdate, taskInfoOpen, setTaskInfoOpen, handleTaskClick, selectedSpace, setSelectedSpace, memberModal, setMemberModal, spaceEditModal, setSpaceEditModal,
    steps, setSteps, setTasks, admin, setAdmin, handleSpaceClick, newsModal, setNewsModal, members, setMembers}){

    const [spaces, setSpaces] = useState([]);
    const [creator, setCreator] = useState([]);

    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem('userId');

    const filteredTasks = tasks.filter(task => task.spaceId !== null);


    async function getSpaces(){
        try{
            const response = await axios({
                method: 'get',
                url: `${API_ROUTES.SPACES}`,
                headers: {'Authorization': `Bearer ${token}`}
            });
            const fetchedSpaces = response.data.spaces;
            const spaceAdmins = fetchedSpaces.map(space => space.admin).flat();
    
            // Map des membres par espace
            const membersBySpace = {};
            for (const space of fetchedSpaces) {
                membersBySpace[space._id] = space.members;
            }
            
            if (spaceAdmins.includes(userId)){
                setAdmin(spaceAdmins);
            }
    
            setSpaces(fetchedSpaces);
            
            // Récupérer les détails des membres
            const membersResponse = await axios({
                method: 'post',
                url: `${API_ROUTES.PROFILE}`, // Endpoint pour récupérer les détails des utilisateurs
                data: { userIds: Object.values(membersBySpace).flat() },
                headers: { 'Authorization': `Bearer ${token}` }
            });
    
            const membersDetails = membersResponse.data.users;
    
            // Associez les détails des membres avec chaque espace
            const membersDetailsBySpace = {};
            for (const spaceId in membersBySpace) {
                membersDetailsBySpace[spaceId] = membersDetails.filter(member => membersBySpace[spaceId].includes(member._id));
            }
    
            setMembers(membersDetailsBySpace); // Supposons que la réponse contient une liste d'utilisateurs avec leurs détails
        } catch (error) {
            console.log('error fetching spaces : ', error);
        }
    }
    
    

    useEffect(() => {
        getSpaces()
        console.log('liste des admin : ', admin)
        //eslint-disable-next-line
    }, [])

    async function addSpace(name){
        const space = {
            name: name,
        }
        const bodyFormData = new FormData()
        bodyFormData.append('name', JSON.stringify(name))
        try{
            //eslint-disable-next-line
            const response = await axios({
                method: 'post',
                url: `${API_ROUTES.SPACES}`,
                data: bodyFormData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
            setSpaces([...spaces, space]);
            getSpaces()
        } catch(error){
            console.log('error adding new space : ', error)
        }
    }

    async function editSpace(selectedSpace, newData){
        try{
            const response = await axios({
                method: 'put',
                url: `${API_ROUTES.SPACES}`,
                data: {selectedSpace, newData},
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
            setSpaces([...spaces, response.data]);
            getSpaces()
        } catch(error){
            console.log('error editing space : ', error)
        }
    }

    async function deleteSpace(spaceId){
        try{
            //eslint-disable-next-line
            const response = await axios({
                method: 'delete',
                data: {spaceId},
                url: `${API_ROUTES.SPACES}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            setSpaces(spaces.filter(space => space._id !== spaceId));
            getSpaces();
        } catch(error){
            console.log('Error deleting space : ',error)
        }
    }

    return(
        <>
        <Header setTaskInfoOpen={setTaskInfoOpen}/>
        {
            newsModal &&
                <News 
                members={members}
                closeModal={setNewsModal(false)}/>
        }
        <div className="open-space-container">
            <div className="left-panel">
                <div className="spaces-summary">
                    {spaces.filter(space => space.createdBy === userId || space.members.includes(userId)).map((space, index) => (
                        <div key={index} className={`space-card ${space._id === selectedSpace && 'active-space'}`}>  
                            <span onClick={() => {handleSpaceClick(space._id); setAdmin(space.admin); setCreator(space.createdBy); setTaskInfoOpen(null)}}>
                                {space.name}
                            </span>
                            { admin.includes(userId) &&
                            <div className="admin-buttons">
                                <FaEdit title='Edit space' className='admin-icon' onClick={() => {setSpaceEditModal(true); setSelectedSpace(space._id)}}/>
                                <FaRegTrashAlt title='Delete space' className='admin-icon' onClick={() => deleteSpace(space._id)} />
                            </div>
                            }
                        </div>
                    ))}
                </div>

                <IoAdd className='icon add-task' onClick={() => {setSpaceModal(true)}} />

            </div>
                {
                    spaceModal && <Modal /* addSpace={addSpace} */ 
                    closeModal={() => setSpaceModal(false)} 
                    openModal={openModal} 
                    spaceModal={spaceModal}
                    setMembers={setMembers} 
                    members={members}
                    addSpace={addSpace}/>
                }
                {
                    openModal && <Modal
                        openModal={openModal}
                        addTask={addTask}
                        editTask={editTask} 
                        closeModal={() => setOpenModal(false)} 
                        editModal={editModal} 
                        tasks={tasks} 
                        taskIdToUpdate={taskIdToUpdate} 
                        taskName={tasks.find(task => task._id === taskIdToUpdate)?.name} 
                        taskDescription={tasks.find(task => task._id === taskIdToUpdate)?.description}
                        selectedSpace={selectedSpace}
                        members={members}
                        />
                }
                {
                    memberModal && <Modal 
                    closeModal={() => setMemberModal(false)} 
                    memberModal={memberModal}
                    editSpace={editSpace}
                    admin={admin}
                    creator={creator}
                    members={members}
                    selectedSpace={selectedSpace}
                    userId={userId}
                    spaces={spaces}
                    />
                }
                {
                    spaceEditModal && <Modal 
                    closeModal={() => setSpaceEditModal(false)}
                    spaceEditModal={spaceEditModal}
                    selectedSpace={selectedSpace}
                    editSpace={editSpace}
                    members={members}/>
                }
                {
                    editModal && <Modal 
                    editModal={editModal}
                    closeModal={() => setEditModal(false)}
                    editTask={editTask}
                    taskIdToUpdate={taskIdToUpdate}
                    taskName={tasks.find(task => task._id === taskIdToUpdate)?.name} 
                    taskDescription={tasks.find(task => task._id === taskIdToUpdate)?.description}
                    members={members}/>
                }
                {
                    newsModal && <Modal
                    closeModal={() => setNewsModal(false)}
                    newsModal={newsModal}
                    members={members}
                    />
                }
                <div className="work-section">
                    { selectedSpace && (
                        <div className="content">
                            <div className="tasks">
                                {filteredTasks.filter(task => task.spaceId === selectedSpace).map(task => (
                                    <div key={task._id} className={`task-card ${task._id === taskInfoOpen && 'selected-task'}`}>
                                        <span onClick={() => handleTaskClick(task._id)}>
                                            {task.name}
                                        </span>
                                        { admin.includes(userId) &&
                                        <div className="admin-buttons">
                                            <FaEdit title='Edit task' className='admin-icon' onClick={() => {setEditModal(true); setTaskIdToUpdate(task._id)}}/>
                                            <FaRegTrashAlt title='Delete task' className='admin-icon' onClick={() => {deleteTask(task._id); setTaskIdToUpdate(task._id)}} />
                                        </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            <IoAdd className='icon add-task' onClick={() => setOpenModal(true)} />
                            <FaUser className='add-member' onClick={() => setMemberModal(true)}/>
{/*                             <HiQuestionMarkCircle className='news' onClick={() => setNewsModal(true)}/>
 */}                        </div>
                    )}
                    {
                    taskInfoOpen &&
                    <section className="task-details">
                        <div className="task-info">
                            {
                                tasks.map((task) => (
                                    task._id === taskInfoOpen && 
                                    <div key={task._id}>
                                        { task.description &&
                                            <div className="description-head">
                                                <div className="description-text">
                                                    {task.description}
                                                </div>
                                            </div>
                                        }
                                        <TaskInfo
                                        taskInfoOpen={taskInfoOpen}
                                        task={task} 
                                        tasks={tasks} 
                                        setTasks={setTasks} 
                                        editTask={editTask} 
                                        taskId={task._id} 
                                        stepId={task.steps.stepId} 
                                        steps={steps}
                                        setSteps={setSteps}
                                        token={token}
                                        getTasks={getTasks}
                                        admin={admin}
                                        userId={userId}/>
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                    }
                </div>
        </div>
        </>
    )
}

export default OpenSpace