import Header from '../components/header/Header';
import Modal from '../components/modal/Modal';
import TaskInfo from '../components/taskinfo/TaskInfo';
import News from '../components/news/News';
import '../pages-styles/Tasks.scss';

import { FaRegTrashAlt, FaEdit } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
//eslint-disable-next-line
import { HiQuestionMarkCircle } from "react-icons/hi2";

function Tasks({userId, token, openModal, setOpenModal, editModal, setEditModal, addTask, getTasks, editTask, deleteTask, tasks, setTasks, steps, setSteps,
    taskIdToUpdate, setTaskIdToUpdate, taskInfoOpen, setTaskInfoOpen, handleTaskClick, admin, setNewsModal, members, newsModal}) {

        const filteredTasks = tasks.filter(task => task.spaceId === null && task.userId === userId)

    return (
        <>
            <Header setTaskInfoOpen={setTaskInfoOpen}/>
            <div className="home-container">
                {
                    newsModal &&
                        <News 
                        members={members}
                        closeModal={setNewsModal(false)}/>
                }
                {
                    openModal && 
                    <Modal addTask={addTask} 
                        closeModal={() => setOpenModal()} 
                        openModal={openModal}
                        members={members}/>
                }
                {
                    editModal &&
                    <Modal editTask={editTask} 
                        closeModal={() => setEditModal()} 
                        editModal={editModal} 
                        tasks={tasks} 
                        taskIdToUpdate={taskIdToUpdate} 
                        taskName={tasks.find(task => task._id === taskIdToUpdate)?.name} 
                        taskDescription={tasks.find(task => task._id === taskIdToUpdate)?.description}
                        members={members}/> 
                    
                }
                <section className="task-panel">
                    <div className="tasks-container">
                        {filteredTasks.map((task, index) => (
                            <div key={index} className={`task ${task._id === taskInfoOpen && 'selected-task'}`}>
                                <span className='task-name' onClick={(e) => { e.preventDefault(); handleTaskClick(task._id); }}>{task.name}</span>
                                <FaEdit className='task-icon' onClick={() => {
                                setTaskIdToUpdate(task._id); // Définir l'ID de la tâche à mettre à jour
                                setEditModal(true);
                                }} />                            
                                <FaRegTrashAlt className='task-icon' onClick={() => deleteTask(task._id)} />
                            </div>
                        ))}
                        <IoAdd className='icon add-task' onClick={() => setOpenModal(!openModal)} />
                    </div>
{/*                     <HiQuestionMarkCircle className='news' onClick={() => setNewsModal(true)}/>
 */}                </section>
                {
                taskInfoOpen &&
                <section className="task-details">
                    <div className="task-info">
                        {
                            tasks.map((task) => (
                                task._id === taskInfoOpen && 
                                <div key={task._id}>
                                    { task.description &&
                                        <div className="description-head">
                                            <div className="description-text">
                                                {task.description}
                                            </div>
                                        </div>
                                    }
                                    <TaskInfo
                                    taskInfoOpen={taskInfoOpen}
                                    task={task} 
                                    tasks={tasks} 
                                    setTasks={setTasks} 
                                    editTask={editTask} 
                                    taskId={task._id} 
                                    stepId={task.steps.stepId} 
                                    steps={steps}
                                    setSteps={setSteps}
                                    token={token}
                                    getTasks={getTasks}
                                    admin={admin}/>
                                </div>
                            ))
                        }
                    </div>
                </section>
                }
            </div>
        </>
    )
}

export default Tasks;
