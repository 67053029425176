import Header from '../components/header/Header'
import GeneralInfo from '../components/generalInfo/GeneralInfo'
import Security from '../components/security/Security'
import '../pages-styles/Profile.scss'

import { API_ROUTES } from '../utils/constants'
import axios from 'axios'
import { useState, useEffect } from 'react'

function Profile({tasks, steps, userId, token, setTasks, setSteps, setTaskInfoOpen}){

    const [userName, setUserName] = useState('');


    const [imageUrl, setImageUrl] = useState('');

    const [openGeneral, setOpenGeneral] = useState(false);
    const [openSecurity, setOpenSecurity] = useState(false);
    // eslint-disable-next-line
    const [openSpaces, setOpenSpaces] = useState(false);

    useEffect(() => {
        getUserInfo()
        // eslint-disable-next-line
    }, []);

    async function getUserInfo() {
        if(token){
            const response = await axios ({
                method: 'get',
                url: `${API_ROUTES.PROFILE}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const userInfo = response.data.user
            setUserName(userInfo.username)
            setImageUrl(userInfo.picture)
        }  
    }
    
  // Fonction pour mettre à jour l'URL de l'image
    const handleImageChange = (newImageUrl) => {
        setImageUrl(newImageUrl);
    };

    // Checks if a details section is already open and changes content
    const handleGeneralClosing = () => {
        if(openGeneral){
            setOpenGeneral(false)
        }
    }
    const handleSecurityClosing = () => {
        if(openSecurity){
            setOpenSecurity(false)
        }
    }

    return (
        <>
            <Header setTaskInfoOpen={setTaskInfoOpen}/>
            <div className="profile-container">
                <div className="info-box">
                    <div className="left-section">
                        <div className="general-info" onClick={() => {setOpenGeneral(!openGeneral); handleSecurityClosing()}}>General informations</div>
                        <div className="security" onClick={() => {setOpenSecurity(!openSecurity); handleGeneralClosing()}}>Security</div>
                        {/* <div className="shared-spaces" onClick={() => setOpenSpaces(!openSpaces)}>My shared spaces</div> */}
                    </div>
                    <div className="right-section">
                    {
                        openGeneral &&
                                <div className="general-info-details">
                                    <GeneralInfo userId={userId} tasks={tasks} steps={steps} setTasks={setTasks} setSteps={setSteps} token={token} userName={userName} setUserName={setUserName} imageUrl={imageUrl} setImageUrl={setImageUrl} handleImageChange={handleImageChange}/>
                                </div>
                    }
                    {
                        openSecurity &&
                        <div className="security-details">
                            <Security />
                        </div>
                    }
                    {/* {
                        openSpaces &&
                        <div className="shared-spaces-details"></div>
                    } */}
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile