   const API_URL = 'https://todo-u29n.onrender.com';
 /* const API_URL = 'http://localhost:4000'; */
 export const API_ROUTES = {
  SIGN_UP: `${API_URL}/api/auth/signup`,
  SIGN_IN: `${API_URL}/api/auth/login`,
  PROFILE:`${API_URL}/api/auth/profile`,
  TASKS: `${API_URL}/api/tasks`,
  SPACES: `${API_URL}/api/spaces`,
};

