import '../pages-styles/Sign.scss'
import Header from '../components/header/Header'
import React, { useState } from 'react';
import axios from 'axios';
import { API_ROUTES } from '../utils/constants';

function Sign(){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const [loading, setLoading] = useState(false);
    //eslint-disable-next-line
    const [signupLoading, setSignupLoading] = useState(false);
    const [timeoutReached, setTimeoutReached] = useState(false); // Nouvelle variable d'état pour indiquer si le délai est atteint


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSignup = (event) => {
        event.preventDefault(); // Empêche le comportement de soumission par défaut du formulaire

        // Expression régulière pour vérifier le format de l'adresse e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Expression régulière pour valider le mot de passe
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?,:;/@#$%^&*()_-])[A-Za-z\d!?,:;/@#$%^&*()_-]{8,}$/;

        // Vérifier si l'adresse e-mail est au bon format
        if (!emailRegex.test(email)) {
            // Afficher un message d'erreur si l'adresse e-mail est invalide
            setErrorMessage('Wrong email adress format');
            return; // Arrêter l'exécution de la fonction si l'adresse e-mail est invalide
        }
        // Vérifier si le mot de passe est valide
        if (!passwordRegex.test(password)) {
            // Afficher un message d'erreur si le mot de passe est invalide
            setErrorMessage('Password must contain at least 8 characters, 1 uppercase and 1 lowercase letter, 1 digit and 1 special character among !?,:;/@#$%^&*()_-');
            return; // Arrêter l'exécution de la fonction si le mot de passe est invalide
        }
        setSignupLoading(true)
        setErrorMessage('Your informations are being checked by our higly qualified employees, please wait...')

        // Définir un délai de 2 secondes avant d'activer l'écran de chargement
        const timeout = setTimeout(() => {
            setTimeoutReached(true);
        }, 2000);

        // Envoyer les données d'inscription au backend
        axios.post(API_ROUTES.SIGN_UP, { email, password })
            .then(res => {
                clearTimeout(timeout)
                // Traitement de la réponse en cas d'inscription réussie
                setErrorMessage('Successfully signed up!');
                setTimeout(() => {
                    handleLogin(event);
                }, 2000)
            })
            .catch(error => {
                // Traitement de l'erreur en cas de problème avec l'inscription
                return(error)
        })

        // Activer le chargement seulement si le délai est atteint
        setTimeout(() => {
            if (!loading && timeoutReached) {
                setLoading(true);
            }
        }, 0);
    };

    const handleLogin = (event) => {
        event.preventDefault(); // Empêche le comportement de soumission par défaut du formulaire

        // Définir un délai de 2 secondes avant d'activer l'écran de chargement
        const timeout = setTimeout(() => {
            setTimeoutReached(true);
        }, 2000);

        // Envoyer les données de connexion au backend
        axios.post(API_ROUTES.SIGN_IN, { email, password })
        .then(res => {
            // Annuler le délai si la requête est terminée avant
            clearTimeout(timeout);

            // Traitement de la réponse en cas de connexion réussie
            sessionStorage.setItem('token', res.data.token);
            sessionStorage.setItem('userId', res.data.userId)
            window.location = '/Tasks'
        })
        .catch(error => {
            // Annuler le délai en cas d'erreur
            clearTimeout(timeout);

            // Traitement de l'erreur en cas de problème avec la connexion
            console.error(error);
            setErrorMessage('Incorrect email or password')
        })
        .finally(() => {
            setLoading(false); // Arrêter l'écran de chargement
        });

        // Activer le chargement seulement si le délai est atteint
        setTimeout(() => {
            if (!loading && timeoutReached) {
                setLoading(true);
            }
        }, 0);
    };

    return (
        <>
            <Header />
            <div className="sign-container">
                {   !loading ?
                    <form onSubmit={handleLogin}>
                        <div className="error-box">{errorMessage}</div>
                        <input name='email' type="text" placeholder='Email' value={email} onChange={handleEmailChange}/>
                        <input name='password' type="password" placeholder='Password' value={password} onChange={handlePasswordChange}/>
                        <div className="buttons">
                            <button type='submit'>Sign In</button>
                            <button type='button' onClick={handleSignup}>Sign Up</button>
                        </div>
                    </form>
                    :
                    <div className="loading-container">
                        <h1>Our employees were on lunch break, they are on their way</h1>
                        <h1>Please wait...</h1>
                        <div className="pizza-animation"></div>
                    </div>
                }
            </div>
        </>
    )
}

export default Sign